import React, { useState, useEffect } from 'react';
import qrCodeApi from '../../api/qrcodeApi';
import { FaPhoneAlt } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { TbWorldWww } from "react-icons/tb";
import { FaUserPlus } from "react-icons/fa";
import { FaCopy } from "react-icons/fa";
import DownloadInfoModal from './component/modal';
import AlertBox from '../../components/alert';
import { useLoaderData } from 'react-router-dom';
import CommonHelper from '../../helpers/common';
import FubonLogo from "../../assets/images/fubon_pnt_logo.png";
import Fubon from "../../assets/images/fubon.png";
import { FiLayout } from "react-icons/fi";
import ECard from './component/e-sign';
import NamecardFooter from './component/footer-namecard';
import NamecardInfo from './component/namecard-info';
import { Modal, Button, Image } from 'react-bootstrap';
import "./index.css";

const initialAlert = {
    is_show: false,
    title: "",
    content: "",
    variant: "danger"
};

export async function loader({ params }) {
    var data = {};
    if (params.hash !== undefined && CommonHelper.is_not_empty(params.hash)) {
        const hash = params.hash;
        data = hash;
    }
    return data;
};

const QRCode = () => {
    const [showModal, setShowModal] = useState(false);
    const [employee, setEmployee] = useState(null);
    const [alertData, setAlertData] = useState(initialAlert);
    const hash = useLoaderData();
    const [isEsignCard, setIsEsignCard] = useState(false);
    const [logo_namecard, setLogoNamecard] = useState(FubonLogo);
    const [namecard_template, setNamecardTemplate] = useState("");

    useEffect(() => {
            fetchEmployee();
            fetchNamecardTemplate();
    }, []);

    useEffect(() => {
        if (hash) {
            fetchEmployee();
            fetchNamecardTemplate();
        }
    }, [hash]);

    const fetchEmployee = async () => {
        try {
            const res = await qrCodeApi.get_employee_hashkey(hash);
            if (res.code !== 200) {
                return;
            }
            const convertToObject = JSON.parse(res.data);
            const _data = convertToObject;
            const response = await qrCodeApi.get_employee_by_id(_data.EMP_CODE);
            console.log(_data.EMP_CODE, response)
            if (response.code === 200) {
                const res_logo = await fetchImage(JSON.parse(response.data).ImagePath);
                if(res_logo) {
                    setLogoNamecard(res_logo);
                } else {
                    setLogoNamecard(Fubon);
                }
            } else {
                setLogoNamecard(Fubon);
            }
            const responseData = JSON.parse(response.data);
            setEmployee(responseData);
        } catch (error) {
            console.error("Error fetching employee:", error);
        }
    };
    const fetchNamecardTemplate = async () => {
        try {
            const res = await qrCodeApi.get_commons_by_type("NAMECARD");
            if (res.code === 200) {
                setNamecardTemplate(res.data.flexValue1);
            }
        } catch (error) {
            console.error("Error fetching namecard template:", error);
        }
    };
    const handleOpenModal = () => {
        setShowModal(true);
    };
    const handleModalClose = () => {
        setShowModal(false);
    };
    const handleAddToContacts = () => {
        if (window.confirm("Do you want to add this contact to your contacts?")) {
            const _data = {
                EMP_FULL_NAME: employee?.EMP_FULL_NAME || "No Name",
                EMP_FULLNAME_TW: employee?.EMP_FULLNAME_TW || "No Name",
                EMP_FULLNAME_VN: employee?.EMP_FULLNAME_VN || "No Name",
                EMP_REMARKS_1: employee?.EMP_REMARKS_1 || "No Remark",
                EMP_REMARKS_2: employee?.EMP_REMARKS_2 || "No Remark",
                EMP_REMARKS_3: employee?.EMP_REMARKS_3 || "No Remark",
                EMP_REMARKS_4: employee?.EMP_REMARKS_4 || "No Remark",
                EMP_POSITION_1: employee?.EMP_POSITION_1 || "No Title",
                EMP_POSITION_2: employee?.EMP_POSITION_2 || "No Title",
                EMP_POSITION_3: employee?.EMP_POSITION_3 || "No Title",
                EMP_POSITION_4: employee?.EMP_POSITION_4 || "No Title",
                EMP_MOBILE_PHONE: employee?.EMP_MOBILE_PHONE ? formatPhoneNumber(employee.EMP_MOBILE_PHONE) : "",
                EMP_EMAIL: employee?.EMP_EMAIL || "No Email",
                REV: new Date().toISOString(),
                ORG: "Fubon Insurance VietNam Co.",
                ADDRESS: "15F REE Tower, No.9 Doan Van Bo Street, Dist 4 HCM City 70000 VietNam",
            }
            const vCardData = `${namecard_template.replaceAll("\\n", "\n")
                .replaceAll("$_EMP_FULL_NAME", _data.EMP_FULL_NAME)
                .replaceAll("$_EMP_FULLNAME_TW", _data.EMP_FULLNAME_TW)
                .replaceAll("$_EMP_FULLNAME_VN", _data.EMP_FULLNAME_VN)
                .replaceAll("$_EMP_REMARKS_1", _data.EMP_REMARKS_1)
                .replaceAll("$_EMP_REMARKS_2", _data.EMP_REMARKS_2)
                .replaceAll("$_EMP_REMARKS_3", _data.EMP_REMARKS_3)
                .replaceAll("$_EMP_REMARKS_4", _data.EMP_REMARKS_4)
                .replaceAll("$_EMP_POSITION_1", _data.EMP_POSITION_1)
                .replaceAll("$_EMP_POSITION_2", _data.EMP_POSITION_2)
                .replaceAll("$_EMP_POSITION_3", _data.EMP_POSITION_3)
                .replaceAll("$_EMP_POSITION_4", _data.EMP_POSITION_4)
                .replaceAll("$_EMP_MOBILE_PHONE", _data.EMP_MOBILE_PHONE)
                .replaceAll("$_EMP_EMAIL", _data.EMP_EMAIL)
                .replaceAll("$_ORG", _data.ORG)
                .replaceAll("$_ADDRESS", _data.ADDRESS)
                .replaceAll("$_REV", _data.REV)
                }`;
            const blob = new Blob([vCardData], { type: 'text/vcard' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${employee?.EMP_FULL_NAME || "contact"}.vcf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url); // Clean up the URL object
        }
    };
    const formatPhoneNumber = (internationalNumber) => {
        internationalNumber = internationalNumber.replace(/\./g, '');
        if (internationalNumber.startsWith("+84")) {
            return '0' + internationalNumber.slice(3);
        }
        return internationalNumber;
    }
    const fetchImage = async (imagePath) => {
        try {
            const response = await qrCodeApi.get_namecard_image_by_url(imagePath);
            const blob = response;
            return URL.createObjectURL(blob) || Fubon;
        } catch (error) {
            console.error(error);
            return Fubon;
        }
    };

    return (
            <>
                {
                    !isEsignCard ? (
                        <>
                            <div className="container-fluid body" style={{ backgroundColor: '#f7f7f7' }}>
                                <div className="row">
                                    <div className="qr-code px-0 col-md-6 col-sm-12 offset-md-3">
                                        <div className="item-header">
                                            <div className="px-0 w-100">
                                                <div className="text-center qr-card-wrapper">
                                                    <div className="p-3 img-wrap">
                                                        <img
                                                            style={{
                                                                border: "4px solid white",
                                                                width: '250px',
                                                                height: '250px',
                                                                borderRadius: '50%',
                                                                objectFit: 'cover'
                                                            }}
                                                            className="img-body"
                                                            src={logo_namecard}
                                                            alt='Logo Namecard'
                                                        />
                                                    </div>
                                                    <div className="p-4">
                                                        <h6 style={{ wordWrap: "break-word" }} className="text-white qr-card-title">
                                                            {employee && !CommonHelper.is_empty(employee.EMP_FULL_NAME) && employee.EMP_FULL_NAME}
                                                        </h6>
                                                        <div className="text-white card-subtitle">
                                                            <p style={{ fontSize: '18px' }}>
                                                                {
                                                                    employee
                                                                    && !CommonHelper.is_empty(employee.EMP_POSITION_1)
                                                                    && !CommonHelper.is_empty(employee.EMP_REMARKS_1)
                                                                    && employee.EMP_POSITION_2 + " / " + employee.EMP_POSITION_1
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="fbcard-functions p-3">
                                                        <div className="fbcard-functions-wrapper">
                                                            <a
                                                                href={employee && !CommonHelper.is_empty(employee.EMP_MOBILE_PHONE) && "tel:" + formatPhoneNumber(employee.EMP_MOBILE_PHONE)}
                                                                className=" bg-solid "
                                                                style={{ backgroundColor: '#0dcaf0' }}
                                                            >
                                                                <FaPhoneAlt style={{ fontSize: '25px' }} />
                                                                <small className="dynamicText">Phone</small>
                                                            </a>
                                                            <a
                                                                href={employee && !CommonHelper.is_empty(employee.EMP_EMAIL) && "mailto:" + employee.EMP_EMAIL}
                                                                target="_newEmail"
                                                                className=" bg-solid "
                                                                style={{ backgroundColor: '#0dcaf0' }}
                                                            >
                                                                <AiOutlineMail style={{ fontSize: '25px' }} />
                                                                <small className="dynamicText">Email</small>
                                                            </a>
                                                            <a
                                                                href="https://www.fubonins.com.vn/gioi-thieu"
                                                                target="_blank"
                                                                className="border-right-0  bg-solid "
                                                                rel="noreferrer"
                                                                style={{ backgroundColor: '#0dcaf0' }}
                                                            >
                                                                <TbWorldWww style={{ fontSize: '25px' }} />
                                                                <small className="dynamicText">Website</small>
                                                            </a>
                                                            <a
                                                                href="#"
                                                                className="border-right-0  bg-solid "
                                                                rel="noreferrer"
                                                                style={{ backgroundColor: '#0dcaf0' }}
                                                                onClick={() => setIsEsignCard(!isEsignCard)}
                                                            >
                                                                <FiLayout style={{ fontSize: '25px' }} />
                                                                <small className="dynamicText">Ecard</small>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <NamecardInfo
                                            employee={employee}
                                            formatPhoneNumber={formatPhoneNumber}
                                        />
                                        <div className="">
                                            <button
                                                className="btn btn-block btn-lg btn-fbcard d-none d-sm-block bg-solid"
                                                style={{ width: '100%' }}
                                                onClick={() => handleOpenModal()}
                                            >
                                                <FaCopy style={{ fontSize: '25px', marginRight: '8px' }} />
                                                <span>Copy Card</span>
                                            </button>
                                            <button className="btn btn-add btn-fbcard-round d-sm-none bg-solid" onClick={handleAddToContacts}>
                                                <FaUserPlus style={{ fontSize: '20px', marginRight: '8px', float: 'left', marginTop: '12px' }} />
                                                <span>Add to contacts</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <footer style={{ textAlign: 'center', height: '120px', backgroundColor: '#f7f7f7' }}>
                                <div className="mx-auto">Powered by Fubon Insurance</div>
                            </footer>
                        </>
                    ) : (
                        <>
                            <ECard
                                employee={employee}
                                handleOpenModal={handleOpenModal}
                                handleAddToContacts={handleAddToContacts}
                                formatPhoneNumber={formatPhoneNumber}
                            />
                            <NamecardFooter
                                isEsignCard={isEsignCard}
                                setIsEsignCard={setIsEsignCard}
                            />
                        </>
                    )}
                {showModal && (
                    <DownloadInfoModal
                        showModal={showModal}
                        handleClose={handleModalClose}
                        data={window.location.href}
                    />
                )}
                <AlertBox data={alertData} onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }} />
            </>
        // )
    );
};

export default QRCode;