import React from 'react';
import { FaCity } from 'react-icons/fa';
import { IoPhonePortrait } from 'react-icons/io5';
import { FaPhoneAlt } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';
import { MdLocationOn } from 'react-icons/md';
import { TbWorldWww } from 'react-icons/tb';
import CommonHelper from '../../../helpers/common';

const NamecardInfo = (props) => {
    const { employee, formatPhoneNumber, ...other } = props;
    return (
        <>
            <div className="item-body mt-3 p-3">
                <div className="details-row w-100">
                    <a className="details-info" href='#'>
                        <FaCity style={{ fontSize: '25px', marginRight: '1rem', color: '#089a8d' }} />
                        <div >
                            <small style={{ color: '#b3b4bb' }}>Company</small>
                            <div><p>Fubon Insurance VietNam Co., Ltd.</p></div>
                        </div>
                    </a>
                </div>
                <div className="details-row w-100">
                    <a className="details-info" href={"tel:02839435678"}>
                        <FaPhoneAlt style={{ fontSize: '25px', marginRight: '1rem', color: '#089a8d' }} />
                        <div >
                            <small style={{ color: '#b3b4bb' }}>Telephone</small>
                            <div>{"84-28-3943 5678"}</div>
                        </div>
                    </a>
                </div>
                <div className="details-row w-100">
                    <a
                        className="details-info"
                        href={employee && !CommonHelper.is_empty(employee.EMP_MOBILE_PHONE) && "tel:" + formatPhoneNumber(employee.EMP_MOBILE_PHONE)}
                    >
                        <IoPhonePortrait style={{ fontSize: '25px', marginRight: '1rem', color: '#089a8d' }} />
                        <div>
                            <small style={{ color: '#b3b4bb' }}>Mobile Phone</small>
                            <div>
                                {employee && !CommonHelper.is_empty(employee.EMP_MOBILE_PHONE) && formatPhoneNumber(employee.EMP_MOBILE_PHONE)}
                            </div>
                        </div>
                    </a>
                </div>
                <div className="details-row w-100">
                    <a className="details-info" href={employee && !CommonHelper.is_empty(employee.EMP_EMAIL) && "mailto:" + employee.EMP_EMAIL}>
                        <AiOutlineMail style={{ fontSize: '25px', marginRight: '1rem', color: '#089a8d' }} />
                        <div>
                            <small style={{ color: '#b3b4bb' }}>Email</small>
                            <div className="text-truncate">
                                {employee && !CommonHelper.is_empty(employee.EMP_EMAIL) && employee.EMP_EMAIL}
                            </div>
                        </div>
                    </a>
                </div>
                <div className="details-row w-100">
                    <a
                        className="details-info"
                        href={`https://www.google.com/maps/dir/?api=1&destination=15F+REE+Tower,+No.9+Doan+Van+Bo+Street,+Dist+4,+Ho+Chi+Minh+City,+Vietnam`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <MdLocationOn style={{ fontSize: '40px', marginRight: '1rem', color: '#089a8d' }} />
                        <div>
                            <small style={{ color: '#b3b4bb' }}>Address</small>
                            <div>15F REE Tower, No.9 Doan Van Bo Street, Dist 4 HCM City, Viet Nam</div>
                        </div>
                    </a>
                </div>
                <div className="details-row w-100">
                    <a className="details-info" href="https://www.fubonins.com.vn/gioi-thieu">
                        <TbWorldWww style={{ fontSize: '25px', marginRight: '1rem', color: '#089a8d' }} />
                        <div>
                            <small style={{ color: '#b3b4bb' }}>Website</small>
                            <div>https://www.fubonins.com.vn/gioi-thieu</div>
                        </div>
                    </a>
                </div>
            </div>
        </>
    );
};

export default NamecardInfo;