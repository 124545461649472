import React from 'react';
import "../index.css";
import FubonLogo from "../../../assets/images/fubon_pnt_logo.png";
import { FaUserPlus } from "react-icons/fa";
import { FaCopy } from "react-icons/fa";
import CommonHelper from '../../../helpers/common';

const ECard = (props) => {
    const { employee, handleOpenModal, handleAddToContacts, formatPhoneNumber, ...other } = props;
    return (
        <>
            <div className="ecard-container" style={{ borderLeft: '30px solid rgb(0, 168, 142)' }}>
                <div className="ecard-header" >
                    <img src={FubonLogo} alt="Fubon Insurance Logo" className="ecard-logo" style={{ height: '100%', width: '70%', marginLeft: '120px' }} />
                </div>
                <div className="ecard-body">
                    <h3 className="ecard-name">
                        {
                            (employee && !CommonHelper.is_empty(employee.EMP_FULL_NAME) && employee.EMP_FULL_NAME)
                            + " / " +
                            (employee && !CommonHelper.is_empty(employee.EMP_FULLNAME_TW) && employee.EMP_FULLNAME_TW)
                        }
                    </h3>
                    <p className="ecard-position">
                        {
                            (employee && !CommonHelper.is_empty(employee.EMP_REMARKS_1) && employee.EMP_REMARKS_1)
                            + " - " +
                            (employee && !CommonHelper.is_empty(employee.EMP_REMARKS_2) && employee.EMP_REMARKS_2)
                        }
                    </p>
                    <p className="ecard-department">
                        {
                            (employee && !CommonHelper.is_empty(employee.EMP_POSITION_1) && employee.EMP_POSITION_1)
                            + " - " +
                            (employee && !CommonHelper.is_empty(employee.EMP_POSITION_2) && employee.EMP_POSITION_2)
                        }
                    </p>
                    <div className="ecard-company-info">
                        <p style={{ fontWeight: '700' }}>Fubon Insurance Viet Nam Co., Ltd.</p>
                        <p>15F REE Tower, No.9 Doan Van Bo Street, Dist 4, HCM City, Viet Nam.</p>
                        <p>
                            Mobile: <a href={`tel:${employee && !CommonHelper.is_empty(employee.EMP_MOBILE_PHONE) ? formatPhoneNumber(employee.EMP_MOBILE_PHONE) : ''}`}>
                                {employee && !CommonHelper.is_empty(employee.EMP_MOBILE_PHONE) && employee.EMP_MOBILE_PHONE}
                            </a>
                        </p>
                        <p>
                            Email: <a href={`mailto:${employee && !CommonHelper.is_empty(employee.EMP_EMAIL) ? employee.EMP_EMAIL : ''}`}>
                                {employee && !CommonHelper.is_empty(employee.EMP_EMAIL) && employee.EMP_EMAIL}
                            </a>
                        </p>
                    </div>
                    <div className="">
                        <button
                            className="btn btn-block btn-lg btn-fbcard d-none d-sm-block bg-solid"
                            style={{ width: '100%' }}
                            onClick={() => handleOpenModal()}
                        >
                            <FaCopy style={{ fontSize: '25px', marginRight: '8px' }} />
                            <span>Copy Card</span>
                        </button>
                        <button className="btn btn-add btn-fbcard-round d-sm-none bg-solid" onClick={handleAddToContacts}>
                            <FaUserPlus style={{ fontSize: '20px', marginRight: '8px', float: 'left', marginTop: '12px' }} />
                            <span>Add to contacts</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ECard;
