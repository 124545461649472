import React from 'react';

const NamecardFooter = (props) => {
    const { isEsignCard, setIsEsignCard, ...other } = props;
    return (
        <>
            <footer style={{ textAlign: 'center', height: '120px', backgroundColor: '#f7f7f7' }}>
                <div className="mx-auto mt-2">Powered by Fubon Insurance</div>
                <div className='row mt15px'>
                    <div
                        className='col-md-12'
                        style={{
                            cursor: 'pointer',
                            fontWeight: '700',
                            fontSize: '20px',
                            textDecoration: 'underline',
                            color: 'blue', 
                        }}
                        onClick={() => setIsEsignCard(!isEsignCard)}
                    >
                        <p style={{backgroundColor: '#ccc', borderRadius: '0px', padding: '8px' }}>BACK TO</p>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default NamecardFooter;